












































































































































































































import Vue from 'vue'
import { Component } from 'vue-property-decorator'
import b64toBlob from 'b64-to-blob'
import moment from 'moment'
import { appendSitePrefix } from '@/utils/routeUtils'

@Component
export default class ReportsSuppliersPurchasesCreate extends Vue {
  $refs: Vue['$refs'] & {
    form: {
      validate: any
    }
    sendReportForm: {
      validate: any
    }
  }

  appendSitePrefix = appendSitePrefix

  selected = []

  sendReportDialog = false
  sendReportEmails = null
  sendReportSubject = null
  sendReportMessage = null
  sendReportStatus = false

  filteringPanel = [true]
  filterLoading = false
  createReportLoading = false

  headers = []
  search = ''

  suppliers = []
  currencies = []

  filteringSupplier = null
  filteringCurrency = null

  filteredSupplier = null
  filteredCurrency = null

  purchasesData = []

  pagination = {
    descending: true,
    page: 1,
    itemsPerPage: 15,
    sortBy: [],
  }

  get filterRequestData() {
    return {
      supplier_id:
        this.filteringSupplier !== null && this.filteringSupplier !== undefined ? this.filteringSupplier.id : null,
      currency:
        this.filteringCurrency !== null && this.filteringCurrency !== undefined ? this.filteringCurrency.id : null,
    }
  }

  get saveRequestData() {
    return {
      selected_rows: this.selected,
      supplier_id:
        this.filteredSupplier !== null && this.filteredSupplier !== undefined ? this.filteredSupplier.id : null,
      currency:
        this.filteredCurrency !== null && this.filteredCurrency !== undefined ? this.filteredCurrency.id : null,
      send_report_status: this.sendReportStatus,
      send_report_emails: this.sendReportEmails,
      send_report_subject: this.sendReportSubject,
      send_report_message: this.sendReportMessage,
    }
  }

  created() {
    this.$axios.get('/v3/reports/suppliers/purchases/create/get_initial_data').then((response) => {
      this.headers = response.data.data.headers
      this.suppliers = response.data.data.suppliers
      this.currencies = response.data.data.currencies
    })
  }

  getPurchasesData() {
    if (this.$refs.form.validate()) {
      const self = this
      self.filterLoading = true
      self.filteredSupplier = self.filteringSupplier
      self.filteredCurrency = self.filteringCurrency

      this.$axios
        .post('/v3/reports/suppliers/purchases/create/get_purchases_data', this.filterRequestData)
        .then((response) => {
          this.purchasesData = response.data.data.purchasesData
        })
        .finally(function () {
          self.filterLoading = false
        })
    }
  }

  createReport(sendEmail) {
    if (!sendEmail || this.$refs.sendReportForm.validate()) {
      const self = this
      this.sendReportDialog = false
      this.sendReportStatus = sendEmail

      this.$axios
        .post('/v3/reports/suppliers/purchases/create/save', this.saveRequestData)
        .then(function (response) {
          self.getPurchasesData()
        })
        .finally(function () {
          self.$router.push({
            name: 'Suppliers/Purchases/Reports',
          })
        })
    }
  }

  goToListReports() {
    this.$router.push({
      name: 'Suppliers/Purchases/Reports',
    })
  }
}
